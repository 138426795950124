import React from 'react';

const Loading = () => {
  return (   
<div class=" w-full h-[100vh] justify-center flex items-center">
  <div className='loader'></div>
</div>
  );
};

export default Loading;
