// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ReturnButton';
import MesCours from './pages/MesCours';
import Apropos from './pages/Apropos';
import Home from './pages/Accueil';
import Loading from './components/Loading';
import './styles/App.css';
import ContactForm from './components/ContactForm';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);


  

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/a-propos" element={<Apropos />} />
        <Route path="/mes-cours" element={<MesCours />} />
      </Routes>
      <ContactForm />
      <Footer />
      <ScrollToTopButton />
    </Router>
  );
};

export default App;
