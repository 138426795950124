import React from "react";
import '../styles/App.css';
import Button from "../components/Button";
import Testimonials from "../components/Temoinages";
import Bienfait from "../components/BienfaitDuYoga";
import Citation from "../components/Citation";
import GridLayout from "../components/GridLayout";
import Tarifs from "../components/Tarifs";
import ParallaxImage from "../components/ParallaxImage";

const Accueil = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center h-[auto]">
        {/* section hero */}
        <div className="p-4 lg:px-[5%]  2xl:px-[25%] mt-[50%] items-center lg:mt-[200px]  text-center gap-[80px] flex align-middle flex-col">
          <div className="animate__animated animate__slideInDown flex flex-col gap-6 lg:w-[80%] mx-[auto] ">
          <h1 className="text-[1.7rem] md:text-[3.5rem] lg:leading-[4.2rem] font-extrabold">
  Le <span className="animate-bounce bg-gradient-to-br from-blue-100 to-pink-50 text-blue-500 py-2 px-8 rounded-full text-[1.7rem] md:text-[3.3rem] lg:leading-[4.2rem] font-extrabold">yoga aérien</span>, une{" "}
  <span className="text-[1.7rem] md:text-[3.5rem] lg:leading-[4.2rem] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500">
    expérience
  </span>{" "}
  unique pour le corps et l’esprit.
</h1>
            <h2 className="text-gray-400 text-xl ">
              Élevez-vous avec nos cours de yoga aérien uniques à Bruxelles
            </h2>

            <div className="">
              <Button
                label={"Réservez votre séance dès maintenant"}
                customLink={"#tarif"}
              />
            </div>
          </div>

          <div className="animate__animated animate__slideInUp rounded-xl">
            <img
            className="rounded-xl"
              src="./assets/image--drag-in-to-fill@2x.png"
              alt="yoga-studio"
            />
          </div>
        </div>

        {/* section temoinages */}

        <div className="">
          <Testimonials />
        </div>

        {/* Gird section decouvrez */}
        <div className="bg-[#EDF2F5] px-4 lg:px-[5%] lg:py-[5%] py-10 2xl:px-[25%]">
          <div className="gap-[80px] lg:gap-[80px] flex flex-col">
            <div className="grid lg:grid-cols-4 items-start lg:space-x-8 right-block">
              <div className=" col-span-2 flex flex-col gap-[20px] mb-8 lg:mb-0 ">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[1.6rem] md:text-[2.6rem] font-bold">
                    Découvrez nos services
                  </h1>
                  <p className="text-gray-400 text-xl lg:w-[80%]">
                    Plongez dans un univers de bienveillance, de sérénité et de
                    bien-être. Nos cours de yoga aérien sont conçus pour vous
                    aider à diminuer vos peurs, vos doutes, votre stress et vos
                    douleurs.
                  </p>
                </div>
                <div>
                  <Button label={"En savoir plus"} customLink={"/a-propos"} />
                </div>
              </div>
              <div className=" col-span-2 ">
                <img
                  className="w-full h-full object-cover rounded-lg"
                  src="./assets/image@2x.png"
                  alt="Découvrez le Yoga Aérien"
                />
              </div>
            </div>

            {/* ----------- */}

            <div className="grid lg:grid-cols-4 items-start lg:space-x-8 left-block">
              <div className=" col-span-2 flex flex-col gap-[20px]  mb-8 lg:hidden">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[1.6rem] md:text-[2.6rem] font-bold">
                    Rejoignez nos cours uniques
                  </h1>
                  <p className="text-gray-400 text-xl">
                    Nous proposons des cours de yoga aérien pour deux raisons
                    essentielles :
                  </p>
                  <ol className="flex flex-col gap-3 text-gray-400 lg:text-xl">
                    <li>
                      Aucun studio à Bruxelles n’est exclusivement dédié au yoga
                      aérien.
                    </li>
                    <li>
                      Nos cours sont limités à 5 participants, contrairement aux
                      grandes classes de 40 élèves, afin de respecter les
                      bienfaits du yoga.
                    </li>
                    <li>
                      +32 474 193 019
                    </li>
                  </ol>
                </div>
                <div>
                  <Button label={"En savoir plus"} customLink={"/"} />
                </div>
              </div>

              <div className=" col-span-2 mb-6 lg:mb-0">
                <img
                  className="w-full h-full object-cover rounded-lg"
                  src="./assets/image-31@2x.png"
                  alt="Découvrez le Yoga Aérien"
                />
              </div>

              <div className=" col-span-2 flex flex-col gap-[40px]  max-sm:hidden">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[1.6rem] md:text-[2.6rem] font-bold">
                    Rejoignez nos cours uniques
                  </h1>
                  <p className="text-gray-400 text-xl lg:w-[80%]">
                    Nous proposons des cours de yoga aérien pour deux raisons
                    essentielles :
                  </p>
                  <ul className="flex flex-col gap-3 lg:w-[80%]">
                    <li className="text-gray-400 text-xl">
                      Aucun studio à Bruxelles n’est exclusivement dédié au yoga
                      aérien.
                    </li>
                    <li className="text-gray-400 text-xl">
                      Nos cours sont limités à 5 participants, contrairement aux
                      grandes classes de 40 élèves, afin de respecter les
                      bienfaits du yoga.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bienfaits du yoga */}
        <Bienfait />
        {/* Citation de yoga */}
        <Citation />
        {/* Bienfaits du yoga */}
        <GridLayout />
        {/* Citation de yoga */}
        <Citation />
        {/* Tarif */}
        <Tarifs />
        {/* ParallaxImage*/}
        <ParallaxImage />
      </div>
    </>
  );
};

export default Accueil;
