import React from "react";


const Citation = () => {
  return (
    <div class="wrapper bg-blue-500 py-8 h-[20vh] lg:py-[20vh]">
  <div class="item item1 shadow-xl p-8 rounded-[50%] bg-blue-700  ">
    <img src="./assets/aerial.png" alt="logo"/>
  </div>
  <div class="item item2 shadow-xl max-sm:text-white p-8 text-blue-700 rounded-[50%] bg-blue-700  "></div>
  <div class="item item3 shadow-xl max-sm:text-white p-8 text-blue-700 rounded-[50%] bg-blue-700  "><img src="./assets/aerial.png" alt="logo"/></div>
  <div class="item item4 shadow-xl max-sm:text-white p-8 text-blue-700 rounded-[50%] bg-blue-700  "></div>
  <div class="item item5 shadow-xl max-sm:text-white p-8 text-blue-700 rounded-[50%] bg-blue-700  "><img src="./assets/aerial.png" alt="logo"/></div>
  <div class="item item6 shadow-xl max-sm:text-white p-8 text-blue-700 rounded-[50%] bg-blue-700  "></div>
  <div class="item item7 shadow-xl max-sm:text-white p-8 text-blue-700 rounded-[50%] bg-blue-700  "><img src="./assets/aerial.png" alt="logo"/></div>
  <div class="item item8 shadow-xl max-sm:text-white p-8 text-blue-700 rounded-[50%] bg-blue-700  "></div>
</div>
  );
};

export default Citation;
