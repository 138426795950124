import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const [showThankYou, setShowThankYou] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Envoyer les données du formulaire à Formspree
      await axios.post('https://formspree.io/f/meojjqzz', formData);

      // Afficher le message de remerciement
      setShowThankYou(true);

      // Log success or redirect the user
      console.log('Form submitted successfully!');
      // Vous pouvez rediriger l'utilisateur ou afficher un message de succès comme nécessaire

    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
      // Gérer l'erreur, afficher un message d'erreur ou rediriger l'utilisateur
    }

    // Réinitialiser le formulaire après la soumission
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    });

    // Masquer le message de remerciement après quelques secondes
    setTimeout(() => setShowThankYou(false), 5000);
  };

  return (
    <div className="h-auto text-left">
      <div className='w-[80%] mx-auto'></div>

      <div className='py-[8%] lg:px-[5%]'>
        <div className="lg:grid lg:grid-cols-6 px-4 lg:space-x-[120px] 2xl:px-[25%] items-center up-block">
          <div className="col-span-3 card">
            <div className='mb-8'>
              <h1 className='text-[2rem] md:text-[2.6rem] font-extrabold mb-2'>
                Contactez-nous
              </h1>
              <h2 className='text-gray-400 text-xl'>
                Nous sommes là pour répondre à toutes vos questions
              </h2>
            </div>
            <form className='flex flex-col lg:gap-y-10' onSubmit={handleSubmit}>
              <div className="mb-4 flex flex-wrap lg:flex-nowrap gap-2 w-full">
                <div className='w-full'>
                  <label htmlFor="firstName">Prénom</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border rounded-md w-full"
                  />
                </div>

                <div className='w-full'>
                  <label htmlFor="lastName">Nom</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border rounded-md w-full"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="email">Adresse e-mail</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 border rounded-md w-full"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="message">Votre message</label>
                <textarea
                  placeholder='Envoyez-nous votre question ou votre message'
                  cols="50"
                  rows="3"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 border rounded-md w-full"
                ></textarea>
              </div>

              <button
                type="submit"
                className="bg-blue-700 w-full text-white font-bold p-5 rounded-lg hover:bg-blue-600"
              >
                Envoyer
              </button>
            </form>
          </div>

          <div className="col-span-3 mt-8 lg:mt-0 ">
            <img className='rounded-xl' src="./assets/hero-image@2x.png" alt="contact us" />
          </div>
        </div>

        {showThankYou && (
          <div className="fixed p-4 top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <img className='w-[13%] mb-2 ' src="./assets/aerial.png" alt="logo"/>
              <h2 className="text-2xl font-bold mb-4">Merci de nous avoir contactés !</h2>
              <p>Nous vous répondrons dès que possible.</p>
              <button
                className="mt-4 bg-blue-700 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600"
                onClick={() => setShowThankYou(false)}
              >
                Fermer
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
