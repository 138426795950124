import React from "react";
import Testimonials from "../components/Temoinages";
import Bienfait from "../components/AutreBienfait";
import Citation from "../components/Citation";
import '../styles/App.css';

const Apropos = () => {
  let Gridlayout = [
    {
      Image: "./assets/image1@2x.png",
    },
    {
      Image: "./assets/image-8@2x.png",
    },
    {
      Image: "./assets/image-11@2x.png",
    },
    {
      Image: "./assets/image-7@2x.png",
    },
  ];

  return (
    <>
      {/* section hero */}

      <div className="flex flex-col justify-center items-center h-[auto] ">
        <div className="p-4 lg:px-[5%]  2xl:px-[25%] lg:mt-[200px] mt-[50%] items-center  text-center gap-[50px] flex align-middle flex-col animate__animated animate__slideInDown ">
          <div className="animate__animated animate__slideInDown flex flex-col gap-8 lg:w-[80%] mx-[auto] ">
            <h1 className="text-[1.7rem] md:text-[3.5rem] lg:leading-[4.2rem] font-extrabold">
              <span className=" text-[1.7rem] md:text-[3.5rem] lg:leading-[4.2rem] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500  ">
                Rejoignez-nous
              </span>{" "}
              pour une expérience unique à{" "}
              <span className=" from-blue-100 to-pink-50 bg-gradient-to-br text-blue-500 py-2 px-8 rounded-full text-[1.7rem] md:text-[3.3rem] lg:leading-[4.2rem] font-extrabold">
                bruxelles
              </span>
            </h1>
            <h2 className="text-gray-400 text-xl ">
              découvrez un nouveau niveau de bien-être pour le corps et l’esprit
            </h2>
          </div>

          <div className=" lg:h-[80vh] rounded-xl overflow-hidden animate__animated animate__slideInUp">
            <img
              className="w-full  object-cover"
              src="./assets/header-with-image@2x.png"
              alt="yoga-studio"
            />
          </div>
        </div>
      </div>

{/* mes horraires */}
<div className=" bg-gray-100 w-full px-4 lg:px-[5%] py-[8%] lg:mt-[100px] mt-[20%]">
  <div className="lg:w-[80%] mx-[auto] flex flex-col justify-center items-center text-center">
          <h2 className="text-3xl font-bold lg;mr-10 mb-10  up-block">Nos horaires</h2>
          <ul className=" text-gray-700 flex flex-row justify-center items-center flex-wrap gap-8">
            <li className=" lg:w-[23%] jump text-xl leading-loose font-medium  px-6 py-4 shadow-lg bg-white rounded-3xl up-block">Tous les matins de <span className="text-2xl font-bold text-blue-700">10h à 11h</span></li>
            <li className=" lg:w-[23%] jump text-xl leading-loose font-medium px-6 py-4 shadow-lg bg-white rounded-3xl up-block">Tous les soirs de <span className="text-2xl font-bold text-blue-700">19h à 20h</span></li>
            <li className=" lg:w-[23%] jump text-xl leading-loose font-medium px-6 py-4 shadow-lg bg-white rounded-3xl up-block">Tous les mardi de <span className="text-2xl font-bold text-blue-700">13h à 14h</span></li>
          </ul>
        </div>
        </div>


{/* section mon histoire */}
      <div className=" h-auto text-left  ">
        <div className=" w-[80%] mx-[auto] "></div>

        <div className="py-[8%] lg:px-[5%] ">
          <div className=" lg:grid lg:grid-cols-6 px-4  lg:space-x-[120px]  2xl:px-[25%] items-start">
            <div className=" col-span-3 card">
              <div className="mb-8 up-block">
                <h1 className="text-[2rem] md:text-[3.3rem]  font-extrabold">
                  Mon Histoire
                </h1>
              </div>

              {/* histoire container */}
              <div className="up-block">
                <p className="leading-loose text-lg text-gray-500">
                  Je m'appelle <span className="font-bold text-xl text-gray-700">Alice</span>. J’ai
                  travaillé 10 ans comme aide-soignante. Juste avant la pandémie
                  de COVID, j’ai fait un faux mouvement avec un appareil médical
                  et je me suis déplacé la rotule et le ménisque gauche.
                  Résultat : opération en urgence. J’ai donc dû arrêter mon
                  travail que j’aimais tant. Sur les conseils de mon médecin,
                  j’ai entrepris des recherches pour une activité physique qui
                  pourrait aider mes genoux sans les endommager. J’ai essayé le
                  yoga "normal" mais cela était difficile car les postures
                  n’étaient pas corrigées par les professeurs et j’avais encore
                  plus mal aux genoux. Alors j’ai recommencé mes recherches et
                  j’ai trouvé le yoga aérien. J’ai trouvé une formation de 250h
                  d’aérial yoga en ligne, pandémie oblige.
                </p>
                <br />
                <p className="leading-loose text-lg text-gray-500">
                  J’ai commencé à pratiquer le yoga aérien seule chez moi avec
                  mes cours en ligne et ma professeur à l’écoute via mail et
                  vidéo. Rapidement, j’ai senti mes genoux se libérer de leur
                  douleur. Mais ce n’était pas tout : j’ai été étonnée de voir à
                  quel point mon corps changeait. Ma posture est différente,
                  j’ai moins de pression dans mes hanches, je suis plus calme et
                  moins stressée. Ma vie change pour devenir meilleure.
                </p>
                <br />
                <p className="leading-loose text-lg text-gray-500">
                  Aujourd’hui, je me consacre à mon studio que j’ai créé
                  exclusivement pour aider le plus de gens possible à améliorer
                  leurs douleurs physiques et mentales grâce à des postures
                  bénéfiques pour le corps et l’esprit.
                </p>
              </div>
            </div>

            <div className="col-span-3 mt-8 lg:mt-0 up-block">
              <img
                src="./assets/hero-image1@2x.png"
                alt="alice-yoga-instructor"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Citation de yoga */}
      <Citation />

      {/* Bienfaits du yoga */}
      <Bienfait />

      {/* section temoinages */}
      <Testimonials />

      {/* Galery */}
      <div className="px-4 py-10 lg:px-[5%] lg:py-[3%]  2xl:px-[25%]">
        <div className="h-auto lg:w-2/3 mx-auto text-center justify-center pb-10 lg:pb-20 up-block">
          <h2 className="text-[1.8rem] md:text-[2.6rem] font-extrabold text-center lg:text-center">
            {" "}
            Galerie
          </h2>
          <p className="text-gray-400 text-xl lg:text-center">
            Découvrez nos moments forts et l'ambiance de nos cours de yoga
            aérien
          </p>
        </div>

        {/* Grid layout section */}
        <div className="lg:grid lg:grid-cols-2 lg:grid-row-2 gap-10">
          {Gridlayout.map((card, index) => (
            <div key={index} className="mb-8 lg:mb-0 up-block">
              <div className="mb-4">
                <img
                  className="rounded-lg w-full h-[400px] object-cover "
                  src={card.Image}
                  alt="galery-img"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Citation de yoga */}
      <Citation />
    </>
  );
};

export default Apropos;
