import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from '../components/Button';
import '../styles/App.css'; // Assurez-vous que le fichier CSS est importé

const Navbar = () => {
  let navLinks = [
    {
      linkName: "Accueil",
      linkUrl: "/",
    },
    {
      linkName: "À Propos",
      linkUrl: "/a-propos",
    },
    {
      linkName: "Mes Cours",
      linkUrl: "/mes-cours",
    },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [slideOut, setSlideOut] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const scrollThreshold = 10; 
    setScrolling(scrollPosition > scrollThreshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    if (mobileMenuOpen) {
      setSlideOut(true);
      setTimeout(() => {
        setMobileMenuOpen(false);
        setSlideOut(false);
      }, 300);
    } else {
      setMobileMenuOpen(true);
    }
  };

  return (
    <div
      className={`${
        scrolling ? "drop-shadow-xl" : "drop-shadow-none"
      } bg-white text-black z-50 flex w-full fixed justify-between px-5 lg:px-[8%] py-5 lg:pt-[30px] items-center 2xl:px-[20%]`}
    >
      <a className="w-fit flex gap-3 items-center" href="/">
        <img
          className="object-cover w-[8vw] lg:w-[4vw] 2xl:w-[4vw]"
          src="./assets/aerial.png"
          alt="Aerial yoga experience"
        />
        <h1 className="text-black font-bold text-[10px] lg:text-lg lg:visible">
          Aerial yoga experience
        </h1>
      </a>

      {/* Desktop Navigation Links */}
      <ul className="flex gap-5 max-sm:hidden items-center">
        {navLinks.map((link, index) => (
          <li key={index} className="p-2">
            <NavLink
              to={link.linkUrl}
              className={
                location.pathname === link.linkUrl
                  ? " text-blue-600 pb-2 font-extrabold uppercase text-sm"
                  : "uppercase text-sm"
              }
            >
              {link.linkName}
            </NavLink>
          </li>
        ))}
        <Button label={"Réserver un cours"} customLink={"https://buy.stripe.com/6oEcQcbPhf5vfZe9AR"} />
      </ul>

      {/* Mobile Icon */}
      <div
        className="text-white text-sm not-italic leading-[normal] whitespace-nowrap justify-center items-stretch flex flex-col px-2 py-1.5 md:hidden cursor-pointer"
        onClick={toggleMobileMenu}
      >
        <img
          className="object-contain"
          src="./assets/menu.svg"
          alt="menu"
        />
      </div>

      {/* Mobile Navigation Menu */}
      {mobileMenuOpen && (
        <div className={`fixed flex-col top-0 left-0 w-full h-[100vh] z-[1000px] bg-blue-50 text-white flex items-center justify-center ${slideOut ? 'slide-out' : 'slide-in'}`}>
          <div
            className="p-5 mb-10 bg-blue-500 rounded-xl cursor-pointer"
            onClick={toggleMobileMenu}
          >
            <img src="./assets/chevron-up.svg" alt="exit" />
          </div>

          <ul className="text-center">
            {navLinks.map((link, index) => (
              <li key={index} className="py-4">
                <NavLink
                  to={link.linkUrl}
                  onClick={toggleMobileMenu}
                  className={
                    location.pathname === link.linkUrl
                      ? "text-pink-300 pb-2 font-extrabold uppercase text-xl"
                      : "uppercase text-xl text-blue-700"
                  }
                >
                  {link.linkName}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
