import React from 'react'


const Bienfait = () => {
  
    let Gridlayout = [
      {
        Header: "Amélioration de la flexibilité :",
        paragraph:
          "Les postures de yoga étirent et allongent les muscles, ce qui aide à maintenir et améliorer la flexibilité des articulations.",
      },
      {
        Header: "Renforcement musculaire :",
        paragraph:
          "Le yoga renforce les muscles qui entourent les articulations, contribuant ainsi à leur stabilité et à leur soutien.",
      },
      {
        Header: "Amélioration de la coordination et de l'équilibre :",
        paragraph:
          "Des articulations stables et renforcées contribuent à une meilleure coordination et à un meilleur équilibre, réduisant ainsi le risque de chutes et de blessures.",
      },
      {
        Header: "Réduction de la douleur articulaire :",
        paragraph:
          "Les exercices de yoga peuvent aider à réduire les douleurs articulaires en améliorant la circulation sanguine et en réduisant l'inflammation.",
      },
      {
        Header: "Amélioration de la posture :",
        paragraph:
          "Une meilleure posture diminue la pression sur les articulations, notamment celles de la colonne vertébrale, des hanches et des genoux.",
      },
      {
        Header: "Diminution du stress sur les articulations :",
        paragraph:
          "Le yoga enseigne des techniques de mouvement et de posture qui répartissent le poids et la pression de manière plus uniforme, réduisant ainsi le stress sur les articulations.",
      },
      {
        Header: "Réduction du risque d'arthrite :",
        paragraph:
          "En maintenant la flexibilité et la force des articulations, le yoga peut aider à prévenir ou à retarder l'apparition de l'arthrite.",
      },
      {
        Header: "Prévention des blessures :",
        paragraph:
          "En renforçant et en stabilisant les articulations, le yoga aide à prévenir les blessures liées aux déséquilibres musculaires et aux mouvements brusques.",
      },
      

    ];
  
    return (
      <div className="px-4 pt-10 lg:px-[10%] lg:py-[10%] 2xl:px-[25%]">
        <div className="h-auto lg:w-2/3 mx-auto text-center justify-center pb-10 lg:pb-20 up-block ">
          <h2 className="text-[1.8rem] md:text-[2.6rem] font-extrabold text-center lg:text-center">
            {" "}
            Les bienfaits du yoga aérien
          </h2>
          <p className='text-gray-400 text-xl  lg:text-center'>Les bienfaits du yoga en mettant <br /> l'accent sur les articulations :</p>
        </div>
  
        {/* Grid layout section */}
        <div className="lg:grid lg:grid-cols-2 lg:grid-row-auto gap-8">
          {Gridlayout.map((card, index) => (
            <div key={index} className="  lg:mx-[auto] mb-10 up-block p-6 bg-blue-100 rounded-xl " >
  
              <div className="">
                <h1 className="text-2xl font-medium mb-3">
                  {card.Header}
                </h1>
                <p className=" text-gray-400 text-left text-lg"> {card.paragraph} </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

export default Bienfait;