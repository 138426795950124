import React from "react";

const GridLayout = () => {
  let Gridlayout = [
    {
      Header: "Initiation au Yoga Aérien",
      paragraph:
        "Découvrez les bases du yoga aérien et apprenez à vous familiariser avec le hamac.",
      Image: "./assets/image-2@2x.png",
     
    },
    {
      Header: "Yoga Aérien Intermédiaire",
      paragraph:
        "Approfondissez vos compétences avec des postures plus complexes et des enchaînements fluides.",
      Image: "./assets/image-3@2x.png",
    
    },
    {
      Header: "Yoga Aérien Avancé",
      paragraph:
        "Maîtrisez des techniques avancées pour un entraînement intense et des sensations fortes.",
      Image: "./assets/image-4@2x.png",
      
    },
    {
      Header: "Yoga Aérien Relaxation",
      paragraph:
        "Un cours doux axé sur la relaxation et la méditation en apesanteur.",
      Image: "./assets/image-5@2x.png",
      
        
    },
  ];

  return (
    <div className="px-4 py-10 lg:px-[5%] lg:py-[10%]  2xl:px-[25%]">
        <div className="h-auto lg:w-[50%]  pb-20 up-block">
          <h2 className="text-[1.8rem] md:text-[2.6rem] font-extrabold text-left">
            {" "}
            Nos Cours
          </h2>
          <p className='text-gray-400 text-xl text-left lg:w-3/4'>Découvrez nos moments forts et l'ambiance de nos cours de yoga aérien l'accent sur les articulations :</p>
        </div>

      {/* Grid layout section */}
      <div className="lg:grid lg:grid-cols-2 lg:grid-row-2 gap-10">
        {Gridlayout.map((card, index) => (
          <div key={index} className="mb-8 lg:mb-0 up-block bg-slate-50 shadow-lg px-2 pt-2 pb-8 rounded-xl" >

            <div className="">
              <div className="mb-4">
                <img className="rounded-lg w-full h-full object-cover" src={card.Image} alt="" srcset="" />
              </div>
              <div className="p-2">
              <h1 className="text-2xl text-blue-700 mb-2 font-bold">
                {card.Header}
              </h1>
              <p className=" text-[#828282] text-left text-lg"> {card.paragraph} </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridLayout;
